module.exports = {
  translated: "سلام رفیق",
  components: {},
    panels_link:{
        home:"مدیریت",
        commercial:"بازرگانی",
        content:"محتوا",
        marketing:"مارکتینگ",
        quality_control:"کنترل کیفیت",
        inventory:"انبار داری",
        support:"پشتیبانی",
        planning:"برنامه ریزی ارسال",
        human_resource:"منابع انسانی",
        legal:"مالی و حسابداری",
    },
  schema: {
    mobile_bottom_nav: {
      home: "خانه",
      grouping: "دسته بندی",
      cart: "سبد خرید",
      profile: "پروفایل",
    },
  },
  router: {},
  route_titles: {
    index: "لیپک",
  },
  views: {},
  attribute: {
    name: "نام",
    phone: "شماره تلفن همراه",
    password: "رمز عبور",
    confirm: "تکرار رمز عبور",
  },
  placeholder: {
    name: "نام را وارد کنید.",
    phone: "شمار همراه خود را وارد کنید.",
    password: "رمز عبور شما باید حداقل 6 کارکتر باشد.",
    confirm: "تکرار رمز عبور را وارد کنید.",
  },
  validations: {
    alpha: "{attribute} فقط می تواند از حروف تشکیل شود",
    alpha_num: "{attribute} فقط میتواند از حروف و اعداد تشکیل شود",
    alpha_dash:
      "{attribute} فقط می تواند از حروف، اعداد، خط فاصله و زیرخط تشکیل شود",
    alpha_spaces: "{attribute} فقط می تواند از حروف و فاصله تشکیل شود",
    between: "{attribute} باید بین {min} و {max} کارکتر باشد",
    confirmed: "{attribute} با رمز عبور مطابقت ندارد",
    digits: "{attribute} باید یک مقدار عددی و دقیقاً {length} رقم باشد",
    dimensions:
      "{attribute} باید در اندازه {width} پیکسل عرض و {height} پیکسل ارتفاع باشد",
    email: "{attribute} باید یک ایمیل(رایانامه) معتبر باشد",
    excluded: "{attribute}باید یک مقدار معتبر باشد",
    ext: "{attribute} باید یک فایل معتبر باشد",
    image: "{attribute} باید یک تصویر باشد",
    max_value: "مقدار {attribute} باید {max} یا کمتر باشد",
    max: "{attribute} نباید بیشتر از {length} کارکتر باشد",
    mimes: "{attribute} باید از نوع معتبر باشد",
    min_value: "مقدار {attribute} باید {min} یا بیشتر باشد",
    minLength: "مقدار {attribute} باید {min} کارکتر یا بیشتر باشد",
    min: "{attribute} باید حداقل {length} کارکتر باشد",
    numeric: "{attribute} فقط می تواند عددی باشد",
    oneOf: "{attribute} باید یک مقدار معتبر باشد",
    regex: "قالب {attribute} قابل قبول نیست",
    required_if: "{attribute} الزامی است",
    required: "{attribute} الزامی است",
    size: "حجم {attribute} کمتر از {size}KB باشد",
    double: "قسمت {attribute} باید یک اعشار معتبر باشد",
    strongPassword:
      "پسورد باید شامل حروف و اعداد و کارکترهای ویژه $#%^&*@ باشد.",
    sameAsPassword: "مقدار {attribute} با رمز عبور برابر نیست.",
  },

  FAILED: "سیستم با مشکل مواجه شده است. لطفا مجددا تلاش کنید.",
  OTP_RESEND_FAILED: "متاسفانه کد تایید ارسال نشد.",
  OTP_RESEND_SUCCESS: "کد تایید با موفقیت ارسال شد.",
  INVALID_GRANT: "رمز عبور اشتباه است.",
  INVALID_OTP: "کد تایید اشتباه است. لطفا مجددا تلاش کنید.",
  ACCESS_DENIED:
    "بعد از 10 دقیقه مجددا تلاش کنید.تعداد در خواست های شما بیش از حد مجاز است.",
  REGISTER_SUCCESS: "شما با موفقیت در لیپک ثبت نام کردید.",
};
