<template>
  <v-app>
    <v-main id="main" v-bind:style="{ 'background-image': 'url(' + bg_image + ')' }">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
    data(){
      return {
        bg_image: require("@/app/assets/images/bg.png")
      }
    }
};
</script>
<style scoped>
  #main{
    background-size: cover;background-repeat: no-repeat
  }
</style>
