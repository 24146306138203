<template>
  <v-container class="d-flex  flex-column justify-center align-center fill-height">
    <h1 class="text-center white--text mb-6">داشبورد لیپک (سهیل)</h1>
    <ul class="d-flex flex-row list-container flex-wrap">
      <li class="white--text text-h6 font-weight-bold"  v-for="item in links" :key="item.title">
          <a :id="item.id" :href="item.to" target="_blank" :key="'link-'+item.title" class="item-link white--text text-decoration-none" rel="noopener noreferrer">
            <span>{{
                $_t(`${item.title}`)
              }}</span>
          </a>
      </li>
    </ul>
  </v-container>
</template>

<script>
import {SCHEMA} from "@/app/schema/main/PANELS_LINK.schema";

export default {
  name: "HelloWorld",

  data: () => ({
    links: SCHEMA,
  }),
};
</script>
<style scoped>
.list-container {
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
}
.list-container > li {
  display: flex;
  flex: 0 1 calc(19%); /* <-- adjusting for margin */
  margin: 5px 5px;
  justify-content: center;
  align-content: center;align-items: center;
  justify-items: center;

}

.list-container > li > a {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  line-height: 220px;
  text-align: center;
  width:  220px  !important;
  height: 220px !important;
}
.item-link:hover {
  box-shadow: 0 8px 32px 0 rgba(172, 175, 206, 0.37);
  font-size: 22px !important;
}
</style>
