import Home from "@/app/views/Home";
const baseRoutes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/app/views/About.vue"),
  },
];

/*
 * import all routes in packages directory /src/packages
 * ------------------------------------
 * @return array of routes
 * ------------------------------------
 * Description:
 * ------------------------------------
 * It allows you to pass in a directory to search,
 * a flag indicating whether subdirectories should be searched too,
 * and a regular expression to match files against.
 * load all file with routes.js extension type in packages directory
 * then return an array of default module in read files
 *  */
function loadRoutes() {
  const context = require.context("@/packages", true, /.*\.routes.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
}

/* load all packages routes in /src/packages */
const resourceRoutes = loadRoutes();

resourceRoutes.forEach((route) => {
  for (let i = 0; i < route.length; i++) {
    baseRoutes.push(route[i]);
  }
});

export default [...baseRoutes];
