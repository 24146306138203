"use strict";

export default [
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: { path: "/" },
    component: () =>
      import(
        /* webpackChunkName: "dashboard-pkg" */ "@/packages/dashboard/presentation/views/Index"
      ),
  },
];
