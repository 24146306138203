module.exports = {
  key: "dashboard",
  dashboard: {
    components: {
    },
    views: {},
    route_titles: {
    },
  },
  attribute: {},
  placeholder: {},
};
