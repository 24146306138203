<template>
  <div>
    <component :is="layout" v-if="layout" />
    <LipakSnackbar />
  </div>
</template>

<script>
import app from "@/config/app.config";
// Load layout components dynamically.
const requireContext = require.context("@/app/layouts", false, /.*\.vue$/);

const layouts = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});
export default {
  name: "App",
  components: { LipakSnackbar: () => import("@/app/components/LipakSnackbar") },
  data() {
    return {
      layout: null,
      title: this.$_t("route_titles.index"),
    };
  },
  methods: {
    setLayout(layout) {
      this.layout = layouts[layout];
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "فروشگاه اینترنتی لیپک برای شما خریدی راحت، مطمئن و اقتصادی با بهترین برندهای گوشی موبایل لپ تاپ و سایر کالاهای دیجیتال، متناسب با بودجه شما",
        },
      ],
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.title,
      // all titles will be injected into this template
      titleTemplate: "%s | " + app.app_name,
    };
  },
};
</script>
