import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

/* Set debug mode only for development mode */
const debug = process.env.NODE_ENV !== "production";

/*
 * import all store modules in packages directory /src/packages
 * ------------------------------------
 * @return array of store modules
 * ------------------------------------
 * Description:
 * ------------------------------------
 * It allows you to pass in a directory to search,
 * a flag indicating whether subdirectories should be searched too,
 * and a regular expression to match files against.
 * load all file with store.js extension type in packages directory
 * then return an array of default module in read files
 *  */
function loadStores() {
  const context = require.context("@/packages", true, /.*\.store.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
}

/*
 * import store general modules in store directory /src/app/store/modules
 * ------------------------------------
 * @return array of store modules
 * ------------------------------------
 * Description:
 * ------------------------------------
 * It allows you to pass in a directory to search,
 * a flag indicating whether subdirectories should be searched too,
 * and a regular expression to match files against.
 * load all file with store.js extension type in packages directory
 * then return an array of default module in read files
 *  */
function loadGeneralsStores() {
  const context = require.context(
    "@/app/store/modules",
    true,
    /.*\.store.js$/i
  );
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
}

/*
 * load all store modules in this project dynamically
 * ------------------------------------
 * @return Object
 *  */
function loadModules() {
  const resourceModules = {};

  /* load global store modules in /src/app/store/modules/ */
  loadGeneralsStores().forEach((resource) => {
    resourceModules[resource.name] = resource;
  });

  /* load all packages store modules in /src/packages */
  loadStores().forEach((resource) => {
    resourceModules[resource.name] = resource;
  });

  return resourceModules;
}

export default new Vuex.Store({
  modules: loadModules(),
  strict: debug,
});
