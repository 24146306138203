import store from "@/app/store/index";

export default (to, from, next) => {
  if (
    !store.getters["authentication/otp_token"] &&
    !store.getters["authentication/check"]
  ) {
    next({ name: "authentication" });
  } else {
    if (
      store.getters["authentication/otp_token"] &&
      !Number(store.getters["authentication/is_registered"])
    )
      next({ name: "register" });
    else {
      next();
    }
  }
};
