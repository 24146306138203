// Application Class
// this class handle app control and settings
const app = {
  app_name: "LIPAK",
  supper_key: "@app_system:",
  fonts: {
    en: "Raleway, sans-serif !important;",
    fa: "IRANSansX, sans-serif !important;",
  },
  default_lang: "fa",
  test_data: false,
  languages: ["en", "fa"],
  limit: 12,
  rtl_lang: ["fa"],
  lang_items: [
    {
      key: "fa",
      title: "فارسی",
    },
    {
      key: "en",
      title: "English",
    },
  ],
};

export default app;
