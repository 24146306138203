export default {
  black: "#000", // black
  white: "#fff", // white
  primary: "#F4561B", // Orange actions, buttons and links
  secondary: "#2C2C54", // Title texts, captions, input fields and everywhere else where black is required
  accent: "#8C8B9B", // Secondary text / Default texts in text field
  more: "#6F727A", // Secondary text / Default texts in text field
  info: "#a7a7a7", // teal lighten-1
  warning: "#F6A609", // Warning
  error: "#FB4E4E", // Error/dislike icon/text
  success: "#2AC769", // Success
  background: "#F2F2F2",
};
