import Vue from "vue";
import VueI18n from "vue-i18n";
import app from "@/config/app.config";

Vue.use(VueI18n);

/*
 * import all locale files  packages directory /src/packages
 * ------------------------------------
 * @return array of locales in packages
 * ------------------------------------
 * Description:
 * ------------------------------------
 * It allows you to pass in a directory to search,
 * a flag indicating whether subdirectories should be searched too,
 * and a regular expression to match files against.
 * load all file with locales.js extension type in packages directory
 * then return an array of default module in read files
 *  */
function loadLocales() {
  const context = require.context("@/packages", true, /locales.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map((m) => m.default); // get `default` export from each resolved module
}

/* load all locale files  in /src/packages */
const resourceLocales = loadLocales();
console.log(resourceLocales, "fa");
let generalLocales = require("./../locales");

/*
 * map imported locales to general locale
 * ------------------------------------
 * @param resource {Array}
 * @param mainLocales {Object}
 * ------------------------------------
 * @return array of locales in packages
 * ------------------------------------
 * Description:
 * ------------------------------------
 * map imported locales to general locale
 * each local in each package import with package key
 *  */
function localeMapper(resource, mainLocales) {
  resource.forEach((locale) => {
    for (const item in locale) {
      if (locale[item].key !== undefined) {
        let package_key = locale[item].key;
        mainLocales[item][package_key] = locale[item][package_key];
      }
      mainLocales[item].attribute = {
        ...mainLocales[item].attribute,
        ...locale[item].attribute,
      };
      mainLocales[item].placeholder = {
        ...mainLocales[item].placeholder,
        ...locale[item].placeholder,
      };
    }
  });

  return mainLocales;
}

/* app all locale files*/
generalLocales = localeMapper([...resourceLocales], { ...generalLocales });

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || app.default_lang,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  silentLocallationWarn: true,
  formatFallbackMessages: true,
  messages: generalLocales,
});

export default i18n;
