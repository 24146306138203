import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./app/router";
import store from "./app/store";
import vuetify from "./app/plugins/vuetify";
import i18n from "./app/plugins/i18n";
import translator from "./app/plugins/translator";
import date_formatter from "./app/plugins/date-formatter";
import currency_formatter from "./app/plugins/currency-formatter";
import Vuelidate from "vuelidate";
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";

Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor, {
  /**
   * Optionally provide the template in the configuration.
   * or register like so Vue.component("FormField", templates.singleErrorExtractor.foundation6)
   */
  template: templates.singleErrorExtractor.foundation6,
  i18n: "validations", // Path to validation messages. Can be deeply.nested.property.
});

Vue.config.productionTip = false;

Vue.use(translator);
Vue.use(date_formatter);
Vue.use(currency_formatter);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
