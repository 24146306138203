export const SCHEMA = [
  {
    id: "management",
    title: "panels_link.home",
    to: process.env.VUE_APP_MANAGEMENT || "https://logistics.zoft.ir/",
  },
  {
    id: "commercial",
    title: "panels_link.commercial",
    to: process.env.VUE_APP_COMMERCIAL || "https://commercial.zoft.ir/",
  },
  {
    id: "content",
    title: "panels_link.content",
    to: process.env.VUE_APP_CONTENT || "https://cui.zoft.ir/",
  },
  {
    id: "marketing",
    title: "panels_link.marketing",
    to: process.env.VUE_APP_MARKETING || "https://marketing.zoft.ir/",
  },
  {
    id: "quality_control",
    title: "panels_link.quality_control",
    to: process.env.VUE_APP_QUALITY_CONTROL || "https://qc.zoft.ir/",
  },
  {
    id: "inventory",
    title: "panels_link.inventory",
    to: process.env.VUE_APP_INVENTORY || "https://inventory.zoft.ir/",
  },
  {
    id: "support",
    title: "panels_link.support",
    to: process.env.VUE_APP_SUPPORT || "https://suport.zoft.ir/",
  },
  {
    id: "planning",
    title: "panels_link.planning",
    to: process.env.VUE_APP_PLANING || "https://logistics.zoft.ir/",
  },
  {
    id: "human_resource",
    title: "panels_link.human_resource",
    to: process.env.VUE_APP_HUMAN_RESOURCE || "#",
  },
  {
    id: "legal",
    title: "panels_link.legal",
    to: process.env.VUE_APP_LEGAL || "https://legal.zoft.ir/panel/supplier/list",
  },
];
