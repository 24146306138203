import dark from "./dark";
import light from "./light";
export default {
  dark: false,
  options: {
    customProperties: true,
    // cspNonce: "dQw4w9WgXcQ",
  },
  themes: {
    dark,
    light,
  },
};
