// initial state
import app from "@/config/app.config";

const state = () => ({
  font: app.fonts.fa,
  lang: app.default_lang || process.env.VUE_APP_I18N_LOCALE,
  locales: ["fa", "en"],
});

// getters
export const getters = {
  font: (state) => state.font,
  lang: (state) => state.lang,
  locales: (state) => state.locales,
};

// mutations
export const mutations = {
  setFont(state, value) {
    state.font = value;
  },
  setLang(state, value) {
    state.lang = value;
  },
  setLocales(state, value) {
    state.locales = value;
  },
};

// actions
export const actions = {
  setSiteFont({ commit }, lang) {
    if (lang === "fa") commit("setFont", app.fonts.fa);
    else commit("setFont", app.fonts.en);
  },
  setLangLocales({ commit }, locales) {
    commit("setLocales", locales);
  },
};

export default {
  namespaced: true,
  name: "lang",
  state,
  getters,
  actions,
  mutations,
};
