import dayjs from "dayjs";
import jalaliday from "jalaliday";
dayjs.extend(jalaliday);
require("dayjs/locale/fa");
require("dayjs/locale/en");

import store from "@/app/store/index";

export default {
  install(Vue) {
    // an instance method
    Vue.prototype.$_date = function (date, type = "short") {
      let lang = store.getters["lang/lang"] || process.env.VUE_APP_I18N_LOCALE;

      let format_type = "DD MMM YYYY";
      if (type === "long") format_type = "DD MMM YYYY, h:mm a";
      if (lang === "en") return dayjs(date).locale("en").format(format_type);
      else if (lang === "fa") {
        format_type = "YYYY/MM/DD";
        if (type === "long") format_type = "YYYY/MM/DD , h:mm a";
        return dayjs(date).calendar("jalali").locale("fa").format(format_type);
      }
    };
  },
};
